<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="financial_account_id" label="财务账户">
					<a-select style="width:220px;" placeholder="请选择账户" v-model="financial_account_id">
						<a-select-option v-for="(item, index) in financeAccountList"
							:value="item.financial_account_id"
							:key="index">{{item.financial_account_name}}</a-select-option>
					</a-select>
        </a-form-model-item>
        <a-form-model-item prop="order_date" label="下单时间">
          <a-range-picker
            style="width:240px"
            v-model="order_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item prop="finish_date" label="完成时间">
          <a-range-picker
            style="width:240px"
            v-model="finish_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item prop="cancel_date" label="取消时间">
          <a-range-picker
            style="width:240px"
            v-model="cancel_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">导出</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { 
	exportFinancialOrderGoods
} from "@/api/finance/analysis.js"

export default {
  data() {
    return {
			order_date: [],
			finish_date: [],
			cancel_date: [],
			financial_account_id: '',

			financeAccountList: [],
    }
  },
  mounted() {
    this.order_date = [
			this.getToday(),
			this.getToday(),
		]
		try{
			let list = localStorage.getItem('financial_account_list')
					list = JSON.parse(list)
			if(list) {
				this.financeAccountList = list
				this.financial_account_id = list[0].financial_account_id
			}
		}catch(e){
			console.log(e)
		}
  },
  methods: {
    
    // 搜索
		async handlerSearch() {
			if(!this.financial_account_id){
				this.$message.warning("请选择财务账户")
				return
			}
			if(this.order_date.length < 2 && this.finish_date.length < 2 && this.cancel_date.length < 2){
				this.$message.warning("至少选择一项时间")
				return
			}

			let params = {
				financial_account_id: this.financial_account_id
			}
			if(this.order_date.length == 2){
				if(new Date(this.order_date[1]).getTime() - new Date(this.order_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("下单时间跨度不能超一个月")
					return
				}
				Object.assign(params, {
					time_start: this.order_date[0] + " 00:00:00",
					time_end:   this.order_date[1] + " 23:59:59",
				})
			}
			if(this.finish_date.length == 2){
				if(new Date(this.finish_date[1]).getTime() - new Date(this.finish_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超一个月")
					return
				}
				Object.assign(params, {
					finish_time_start: this.finish_date[0] + " 00:00:00",
					finish_time_end:   this.finish_date[1] + " 23:59:59",
				})
			}
			if(this.cancel_date.length == 2){
				if(new Date(this.cancel_date[1]).getTime() - new Date(this.cancel_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("取消时间跨度不能超一个月")
					return
				}
				Object.assign(params, {
					cancel_time_start: this.cancel_date[0] + " 00:00:00",
					cancel_time_end:   this.cancel_date[1] + " 23:59:59",
				})
			}
			
			// 新窗口打开页面
			exportFinancialOrderGoods(params)
		},

		getToday(){
			const today = new Date()
			return this.formatDate(today)
		},

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>